import Topbar from '@components/Topbar/Topbar';
import { Box, Stack } from '@mui/material';
import React from 'react';
export default function Frame({ children }) {
    const isWeb = window.location.pathname.includes('web');
    return (React.createElement(Stack, { height: '100%' },
        React.createElement(Stack, { height: '100%', margin: '0 auto', direction: 'column', width: '100%', maxWidth: theme => (isWeb ? '100%' : theme.breakpoints.values.sm) },
            !isWeb && React.createElement(Topbar, null),
            React.createElement(Box, { height: '100%', mb: 2, sx: { p: isWeb ? 0 : 4 } }, children))));
}
