import { Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
const TokenSelect = React.lazy(() => import('@modules/TokenSelect/TokenSelect'));
const WalletSelect = React.lazy(() => import('@modules/WalletSelect/WalletSelect'));
const Web = React.lazy(() => import('@modules/Web/Web'));
export default function Router() {
    return (React.createElement(Suspense, { fallback: React.createElement(Typography, { variant: 'body1', color: 'primary' }, "Loading") },
        React.createElement(Routes, null,
            React.createElement(Route, { path: '/', element: React.createElement(TokenSelect, null) }),
            React.createElement(Route, { path: '/transfer', element: React.createElement(WalletSelect, null) }),
            React.createElement(Route, { path: '/web', element: React.createElement(Web, null) }))));
}
