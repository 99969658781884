import { IconButton, Stack } from '@mui/material';
import React from 'react';
import logo from '@assets/images/logo_large.svg';
import { ArrowLeft } from '@assets/icons/Icons';
import { useNavigate } from 'react-router-dom';
export default function Topbar() {
    const navigate = useNavigate();
    return (React.createElement(Stack, { minHeight: 64, px: 4, direction: 'row', alignItems: 'center', justifyContent: 'space-between' },
        React.createElement(IconButton, { onClick: () => navigate(-1) },
            React.createElement(ArrowLeft, null)),
        React.createElement("img", { height: 22, src: logo })));
}
