import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPayStringAsync, splitPayString } from '@the-registry/tir';
export const DataContext = createContext({
    setSelectedToken: () => { },
    setSelectedWallet: () => { },
    getTokensFromFiat: () => ''
});
export default function DataProvider({ children }) {
    const [paymentInformation, setPaymentInformation] = useState(undefined);
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [amount, setAmount] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [logo, setLogo] = useState(null);
    const [memo, setMemo] = useState(null);
    const [callbackUrl, setCallbackUrl] = useState(null);
    const [selectedToken, setSelectedToken] = useState(undefined);
    const [selectedWallet, setSelectedWallet] = useState(undefined);
    const { search: urlSearch } = useLocation();
    useEffect(() => {
        initialize();
    }, []);
    async function initialize() {
        try {
            const search = new URLSearchParams(urlSearch);
            setName(search.get('name'));
            const address = search.get('address') ?? undefined;
            setAddress(address ?? null);
            setAmount(search.get('amount'));
            setCurrency(search.get('currency'));
            setLogo(search.get('logo'));
            setCallbackUrl(search.get('callbackUrl'));
            setMemo(search.get('memo'));
            const data = await getPaymentInformation(address);
            setPaymentInformation(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    function getCurrencySymbol() {
        switch (currency) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            case 'YEN':
                return '¥';
            default:
                return undefined;
        }
    }
    async function getPaymentInformation(address) {
        if (address) {
            const splitted = splitPayString(address);
            if (splitted) {
                const { prefix, domain } = splitted;
                return await getPayStringAsync(prefix, domain);
            }
        }
    }
    // MOCK METHOD!
    function getTokensFromFiat(chain) {
        let price = 3.01;
        if (chain === 'eth') {
            price = 16000.0;
        }
        if (chain == 'btc') {
            price = 27000.0;
        }
        const tokens = parseFloat(amount ?? '0') / price;
        return tokens.toFixed(5);
    }
    return (React.createElement(DataContext.Provider, { value: {
            name,
            address,
            amount,
            currency,
            currencySymbol: getCurrencySymbol(),
            logo,
            callbackUrl,
            memo,
            paymentInformation,
            setSelectedToken,
            selectedToken,
            selectedWallet,
            setSelectedWallet,
            getTokensFromFiat
        } }, children));
}
export function useData() {
    return useContext(DataContext);
}
