import Frame from '@components/Frame/Frame';
import { theme } from '@misc/theme';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import { ReactQueryProvider } from './context/ReactQueryProvider';
import { BrowserRouter } from 'react-router-dom';
import Router from '@components/Router/Router';
import DataProvider from './context/DataProvider';
export default function App() {
    return (React.createElement(BrowserRouter, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(DataProvider, null,
                React.createElement(ReactQueryProvider, null,
                    React.createElement(Frame, null,
                        React.createElement(Router, null)))))));
}
